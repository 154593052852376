class Gtag {
  constructor(gtag, options, ctx) {
    this.gtag        = gtag
    this.options     = options
    this.ctx         = ctx
    this.initialized = false
    this.enabled     = false
  }

  get ecommerce() {
    return this.options.ecommerce != false
  }

  optIn() {
    this.enabled = true
    this.enable()

    this.init()
  }

  optOut() {
    this.enabled = false
    this.disable()
  }

  init() {
    if (this.enabled && !this.initialized) {
      this.initialized = true
      this.gtag('js', new Date())

      for (const product of this.options.products) {
        this.gtag('config', product.id, { send_page_view: false })
      }
    }

    if (!this.enabled) {
      this.disable()
    }
  }

  enable() {
    delete window[`ga-disable-${this.options.id}`]
  }

  disable() {
    window[`ga-disable-${this.options.id}`] = true
  }

  get activeGtag() {
    return this.ctx.store.get('site/activeGtag')
  }

  trackEvent(name, params, ...args) {
    if (this.enabled) {
      const { category, label, ...data } = params || {}

      if (this.activeGtag) {
        data['send_to'] = this.activeGtag
      }

      if (category) {
        data['event_category'] = category
      }

      if (label) {
        data['event_label'] = label
      }

      this.gtag('event', name, data, ...args)
    }
  }

  trackPage({ path, title }) {
    if (this.enabled) {
      this.gtag('event', 'page_view', {
        page_path: path,
        page_title: title
      })
    }
  }

  trackPurchase(orderData) {
    if (this.ecommerce) {
      this.trackEvent('purchase', orderData)
    }
  }
}

export default (ctx) => {
  const analytics = {"enabled":true,"id":"G-TEESQNL55Y","products":[{"id":"G-TEESQNL55Y"}]}

  if (typeof window !== 'undefined') {
    const primary = analytics.products[0]

    ctx.app.head.script = [
      { hid: 'gtag', src: `https://www.googletagmanager.com/gtag/js?id=${primary.id}`, async: true },
      ...(ctx.app.head.script || [])
    ]

    window.dataLayer = window.dataLayer || []
    function gtag() { dataLayer.push(arguments) }

    const instance = new Gtag(gtag, analytics, ctx)
    ctx.$tracker.register('gtag', instance)

    const { route } = ctx
    const matchParam = route.query[analytics.matchParam]

    if (matchParam) {
      const product = analytics.products.find(({ param }) => param == matchParam)
      ctx.store.set('site/activeGtag', product.id)
    }
  }
}
