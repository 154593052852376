import { render, staticRenderFns } from "./plain.vue?vue&type=template&id=aa5d5032"
import script from "./plain.vue?vue&type=script&lang=js"
export * from "./plain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Layout: require('/opt/build/repo/components/layout/Layout.vue').default})
