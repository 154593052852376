
export default {
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      overflow: 'hidden',
      borderTopWidth: 1
    }
  },
  data() {
    return {
      stuck: false,
    }
  },
  methods: {
    onStick() {
      this.stuck = true
    },
    onUnstick() {
      this.stuck = false
    }
  }
}
