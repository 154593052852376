export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartForm.vue' /* webpackChunkName: "components/eshop-cart-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const OrderModal = () => import('../../components/OrderModal.vue' /* webpackChunkName: "components/order-modal" */).then(c => wrapFunctional(c.default || c))
export const StoresList = () => import('../../components/StoresList.vue' /* webpackChunkName: "components/stores-list" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const DurationInput = () => import('../../components/forms/DurationInput.vue' /* webpackChunkName: "components/duration-input" */).then(c => wrapFunctional(c.default || c))
export const MembersError = () => import('../../components/forms/MembersError.vue' /* webpackChunkName: "components/members-error" */).then(c => wrapFunctional(c.default || c))
export const MembersInput = () => import('../../components/forms/MembersInput.vue' /* webpackChunkName: "components/members-input" */).then(c => wrapFunctional(c.default || c))
export const ContentSection = () => import('../../components/layout/ContentSection.vue' /* webpackChunkName: "components/content-section" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const ImageLayout = () => import('../../components/layout/ImageLayout.vue' /* webpackChunkName: "components/image-layout" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/layout/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const MenuActions = () => import('../../components/layout/MenuActions.vue' /* webpackChunkName: "components/menu-actions" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/layout/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const MenuList = () => import('../../components/menu/MenuList.vue' /* webpackChunkName: "components/menu-list" */).then(c => wrapFunctional(c.default || c))
export const MenuProductCard = () => import('../../components/menu/MenuProductCard.vue' /* webpackChunkName: "components/menu-product-card" */).then(c => wrapFunctional(c.default || c))
export const MenuProducts = () => import('../../components/menu/MenuProducts.vue' /* webpackChunkName: "components/menu-products" */).then(c => wrapFunctional(c.default || c))
export const CreateCart = () => import('../../components/orders/CreateCart.vue' /* webpackChunkName: "components/create-cart" */).then(c => wrapFunctional(c.default || c))
export const OrderAuth = () => import('../../components/orders/OrderAuth.vue' /* webpackChunkName: "components/order-auth" */).then(c => wrapFunctional(c.default || c))
export const OrderLayout = () => import('../../components/orders/OrderLayout.vue' /* webpackChunkName: "components/order-layout" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsList = () => import('../../components/products/ProductsList.vue' /* webpackChunkName: "components/products-list" */).then(c => wrapFunctional(c.default || c))
export const FaqAccordion = () => import('../../components/questions/FaqAccordion.vue' /* webpackChunkName: "components/faq-accordion" */).then(c => wrapFunctional(c.default || c))
export const QuestionTabs = () => import('../../components/questions/QuestionTabs.vue' /* webpackChunkName: "components/question-tabs" */).then(c => wrapFunctional(c.default || c))
export const QuestionsAside = () => import('../../components/questions/QuestionsAside.vue' /* webpackChunkName: "components/questions-aside" */).then(c => wrapFunctional(c.default || c))
export const QuestionsPage = () => import('../../components/questions/QuestionsPage.vue' /* webpackChunkName: "components/questions-page" */).then(c => wrapFunctional(c.default || c))
export const AboutSection = () => import('../../components/sections/AboutSection.vue' /* webpackChunkName: "components/about-section" */).then(c => wrapFunctional(c.default || c))
export const CateringSection = () => import('../../components/sections/CateringSection.vue' /* webpackChunkName: "components/catering-section" */).then(c => wrapFunctional(c.default || c))
export const FaqSection = () => import('../../components/sections/FaqSection.vue' /* webpackChunkName: "components/faq-section" */).then(c => wrapFunctional(c.default || c))
export const HighlightsSection = () => import('../../components/sections/HighlightsSection.vue' /* webpackChunkName: "components/highlights-section" */).then(c => wrapFunctional(c.default || c))
export const MenuSection = () => import('../../components/sections/MenuSection.vue' /* webpackChunkName: "components/menu-section" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/sections/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const OrderOnlineSection = () => import('../../components/sections/OrderOnlineSection.vue' /* webpackChunkName: "components/order-online-section" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection = () => import('../../components/sections/ServicesSection.vue' /* webpackChunkName: "components/services-section" */).then(c => wrapFunctional(c.default || c))
export const ServiceCard = () => import('../../components/services/ServiceCard.vue' /* webpackChunkName: "components/service-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceContactSection = () => import('../../components/services/ServiceContactSection.vue' /* webpackChunkName: "components/service-contact-section" */).then(c => wrapFunctional(c.default || c))
export const ServiceContent = () => import('../../components/services/ServiceContent.vue' /* webpackChunkName: "components/service-content" */).then(c => wrapFunctional(c.default || c))
export const ServicesCarousel = () => import('../../components/services/ServicesCarousel.vue' /* webpackChunkName: "components/services-carousel" */).then(c => wrapFunctional(c.default || c))
export const ValueCard = () => import('../../components/values/ValueCard.vue' /* webpackChunkName: "components/value-card" */).then(c => wrapFunctional(c.default || c))
export const ValuesList = () => import('../../components/values/ValuesList.vue' /* webpackChunkName: "components/values-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
