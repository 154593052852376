
export default {
  data() {
    return {
      quickLinks: [
        {
          path: 'index',
          label: 'pages.home',
          translate: true
        },
        {
          path: '/katalogos',
          label: 'pages.menu',
          translate: true
        },
        {
          path: '/simeia-diathesis',
          label: 'pages.stores',
          translate: true
        },
        {
          path: '/syxnes-erotiseis',
          label: 'pages.faq',
          translate: true
        },
        {
          path: '/sxetika-me-to-yogreen',
          label: 'pages.about',
          translate: true
        }
      ],
      services: [
        {
          path: '/xondriki-polisi',
          label: 'pages.wholesale',
          translate: true
        },
        {
          path: '/etairiki-sitisi',
          label: 'pages.companyOrder',
          translate: true
        },
        {
          path: '/group-order',
          label: 'pages.groupOrder',
          translate: true
        }
      ]
    }
  }
}
