export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"hello@yogreen.gr","address":"Κώστα Βάρναλη 80, Μεταμόρφωση 144 52","geoAddress":"YO GREEN","phone":"+30 212 106 3690","mobile":"+30 6942 053 334"},"en":{"icons":true,"email":"hello@yogreen.gr","address":"Kosta Varnali 80, Metamorfosi 144 52","geoAddress":"YO GREEN","phone":"+30 212 106 3690","mobile":"+30 6942 053 334"}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
