
export default {
  vessel: {
    link: {
      $attrs: 'all',
      display: 'block'
      // xl: { height: 'lg' }
    },
    image: {
      // height: 'md',
      xl: {
        // padding: 'sm',
        // height: 'lg'
      }
    }
  }
}
