module.exports = {
  theme: {
    colors: {
      primary: '#88e13a',
      success: '#88e13a',
      orange: '#f8b501',
      warning: '#f8b501',
      danger: '#ea3138',
      black: '#373430',
      blue: '#80b3ff'
    },
    fonts: {
      sans: [
        'Source Sans Pro',
        'Arial',
        'sans-serif'
      ],
      handwritten: [
        'Mathlete',
        'Arial',
        'sans-serif'
      ],
      mono: [
        'Source code Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'sans',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        font: 'sans',
        leading: 'sm',
        weight: 'semibold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    }
  }
}
