
export default {
  vessel: {
    copyright: {
      fontSize: 'sm',
      marginY: 'sm',
      xl: {
        paddingLeft: 'md',
        marginY: 0
      }
    }
  },
  computed: {
    copyright() {
      const title = this.$t('footer.copyrightTitle')
      const rights = this.$t('footer.rightsReserved')

      return `${title}. ${rights}`
    },
    socialAccounts() {
      return [
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/yogreen_athens'
        }
      ]
    }
  }
}
