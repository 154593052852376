
export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    }
  },
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      overflow: 'hidden',
      borderTopWidth: 1
    }
  }
}
