
export default {
  data() {
    return {
      links: [
        { label: 'pages.menu', route: '/katalogos' },
        { label: 'pages.wholesale', route: '/xondriki-polisi' },
        { label: 'pages.companyOrder', route: '/etairiki-sitisi' },
        { label: 'pages.groupOrder', route: '/group-order' },
        { label: 'pages.about', route: '/sxetika-me-to-yogreen' },
        { label: 'pages.contact', route: '/epikoinonia' },
      ]
    }
  }
}
