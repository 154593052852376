import { render, staticRenderFns } from "./MenuActions.vue?vue&type=template&id=47003186"
import script from "./MenuActions.vue?vue&type=script&lang=js"
export * from "./MenuActions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserSession: require('/opt/build/repo/components/UserSession.vue').default,EshopCartToggle: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CartToggle.vue').default,ScMobileMenu: require('/opt/build/repo/node_modules/@evince/site/lib/packages/MobileMenu.vue').default})
