
export default {
  async fetch() {
    const { $content, $i18n: { locale } } = this
    this.highlights = await $content(locale, 'highlights')
      .sortBy('createdAt')
      .fetch()
  },
  data() {
    return {
      highlights: null
    }
  },
  methods: {
    link({ link }) {
      return this.$pagePath(link)
    },
    image({ image }) {
      return {
        src: image,
        fit: 'contain',
        position: 'bottom',
        ratio: '16/9',
        paddingX: '2xl'
      }
    },
    title({ title }) {
      return {
        html: title,
        tag: 'h3',
        fontWeight: 'semibold'
      }
    },
    text({ description }) {
      return {
        html: description,
        tag: 'p',
        fontWeight: 'light',
        fontSize: 'md'
      }
    }
  }
}
