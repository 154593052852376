export default function({ app, store }, inject) {
  const options = {"continuePath":"\u002Forder-online"}

  inject('cart', {
    get continuePath() {
      return app.$pagePath(options.continuePath)
    },
    get canCheckout() {
      return store.getters['shoppingCart/totalItems'] > 0
    },
    continueShopping() {
      app.$navigateTo(options.continuePath)
    }
  })

  app.router.afterEach((to) => {
    if (!to.path.match('/checkout') && store.state.shoppingCart.token) {
      store.dispatch('shoppingCart/clear')
    }
  })
}
