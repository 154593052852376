
export default {
  vessel: {
    icon: {
      backgroundColor: 'orange',
      borderRadius: 'full',
      padding: '2xs',
      fontSize: 'xl'
    },
    storesLink: {
      $extend: 'icon',
      backgroundColor: 'primary',
      color: 'black',
      fontSize: 'md'
    }
  },
  data() {
    return {
      links: [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.menu', route: '/katalogos' },
        { label: 'pages.wholesale', route: '/xondriki-polisi' },
        { label: 'pages.companyOrder', route: '/etairiki-sitisi' },
        { label: 'pages.groupOrder', route: '/group-order' },
        { label: 'pages.about', route: '/sxetika-me-to-yogreen' },
        { label: 'pages.faq', route: '/syxnes-erotiseis' },
        { label: 'pages.stores', route: '/simeia-diathesis' },
        { label: 'pages.contact', route: '/epikoinonia' },
      ]
    }
  },
  computed: {
    iconStyle() {
      return { width: '28px', height: '28px' }
    }
  },
  methods: {
    toggleMenu() {
      this.$drawer.toggle('main')
    }
  }
}
